<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
            
            <div class="col-md-6">
                <label class="form-label">
                    Start Date <span class="text-danger">*</span>
                </label>
                <flat-pickr
                    v-model="data.started_at"
                    :config="{wrap: true, 
                    altFormat: 'M j, Y H:i',
                    altInput: true,
                    dateFormat: 'Y-m-d H:i',
                    enableTime: true,
                    }"
                    placeholder="Select date"
                    class="form-control flatpickr-input"
                    :class="{ 'is-invalid': v$.data.started_at.$error }"
                ></flat-pickr>
                <div v-for="(item, index) in v$.data.started_at.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    End Date <span class="text-danger">*</span>
                </label>
                <flat-pickr
                    v-model="data.ended_at"
                    :config="{wrap: true, 
                        altFormat: 'M j, Y H:i',
                        altInput: true,
                        dateFormat: 'Y-m-d H:i',
                        enableTime: true,
                        minDate: new Date(),
                    }"
                    placeholder="Select date"
s                   class="form-control flatpickr-input"
                    :class="{ 'is-invalid': v$.data.ended_at.$error }"
              ></flat-pickr>
                <div v-for="(item, index) in v$.data.ended_at.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label for="inputTitle">
                    Title <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.title"
                    :class="{ 'is-invalid': v$.data.title.$error }"
                    id="inputTitle" name="title" class="form-control"
                    placeholder="Enter title" />
                <div v-for="(item, index) in v$.data.title.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
             <div v-if="editMode" class="col-12">
                <label class="">Status</label>
                <select class="form-select" v-model="data.status" name="status" placeholder="--select status--">
                    <option disabled value="" key="-1">-- select status--</option>
                    <option :value="status.value" v-for="(status, index) in statusOptions" :key="index">{{status.label}}</option>
                </select>
            </div>
            <div class="col-12">
                <label class="form-label">Description</label>
                <textarea v-model="data.description" class="form-control"
                rows="2" placeholder="Write Description" name="description"></textarea>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        flatPickr,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                title: "",
                started_at: null,
                ended_at: null,
                status: "pending",
                description: ""
            },
            statusOptions:[
                { value: "pending", label: "Pending" },
                { value: "ongoing", label: "Ongoing" },
                { value: "closed", label: "Closed" },
                { value: "canceled", label: "Canceled" }
            ],
        }
    },
    validations: {
        data:{
            title: {required: required},            
            started_at: {required: required},            
            ended_at: {required: required},  
            status: {required: required},          
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) return;
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/meetings/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("meeting/ADD_MEETING", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
             this.v$.$validate().then(result =>{
                if (!result) return;
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/meetings/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updatedItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
            this.data.start = new Date(this.data.start)
            this.data.end = new Date(this.data.end)
        }
    }
}
</script>